<template>
  <div class="main">
    <van-nav-bar
        title="标题"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
        fixed="true"
    />
    <div>
      <van-cell-group inset style="background-color: #fff;">
        <van-cell title="单元格" value="">
          <template #right-icon>
            <van-switch v-model="checked" />
          </template>
        </van-cell>
        <van-cell title="单元格" value="内容" label="描述信息" />
        <van-cell title="单元格222" value="内容2222" label="描述信息" />

      </van-cell-group>
      <van-button @click="onClick">dialog</van-button>
    </div>

  </div>
</template>
<script>
import { ref } from 'vue';
import { Cell, CellGroup, Switch, Dialog } from 'vant';

export default {
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Switch.name]: Switch,
  },
  setup() {
    const onClick = () => {
      Dialog.alert({
        title: '标题',
        message: '代码是写出来给人看的，附带能在机器上运行。',
      })
    }

    const onClickLeft = onClick

    const checked = ref(true);
    return { checked, onClick, onClickLeft };

  },

}
</script>

<style scoped>
#app {
  background-color: #eff2f5;
}
.main {
  height: 100%;
  text-align: left;
}
</style>
